import type { FC, MouseEvent } from 'react';

import type { CrowdFundingCampaignStatusEnum } from '@/shared/api/codegen/demuzo';
import CampaignProgress from '@ui-kit/molecules/campaign-progress/campaign-progress';
import CardButtons from '@ui-kit/molecules/card-buttons/card-buttons';
import CardInfo from '@ui-kit/molecules/card-info/card-info';
import CardLabel from '@ui-kit/molecules/card-label/card-label';
import GradientBox from '@ui-kit/molecules/gradient-box/gradient-box';
import { Box, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { Snippet } from '@/features/audio-player/types';

type Props = {
  onToggleFavoriteFeature: (evt: MouseEvent<HTMLButtonElement>) => void;
  onPlay: () => void;
  onBuy: () => void;
  href: string;
  crowdFundingCampaignStatus: CrowdFundingCampaignStatusEnum;
  progressToStart: number;
  isFavorite: boolean;
  snippet?: Snippet;
  imageUrl: string;
  totalInvested: number;
  daysLeft: number;
  limit: number;
  progress: number;
  artistName: string;
  productName: string;
  genres: string;
  avatarUrl: string;
  canBuy: boolean;
  sharePrice: number;
};

export const Card: FC<Props> = ({
  crowdFundingCampaignStatus,
  progressToStart,
  onToggleFavoriteFeature,
  onPlay,
  onBuy,
  href,
  isFavorite,
  imageUrl,
  snippet,
  totalInvested,
  daysLeft,
  limit,
  progress,
  artistName,
  productName,
  genres,
  avatarUrl,
  sharePrice,
  canBuy,
}) => {
  return (
    <GradientBox px='20px'>
      <LinkBox>
        <LinkOverlay href={href}>
          <CardInfo
            artistName={artistName}
            productName={productName}
            genres={genres}
            avatarUrl={avatarUrl}
          />
        </LinkOverlay>

        <CampaignProgress
          totalInvested={totalInvested}
          daysLeft={daysLeft}
          limit={limit}
          progress={progress}
          crowdFundingCampaignStatus={crowdFundingCampaignStatus}
          progressToStart={progressToStart}
          progressVariant='default'
          rounded={false}
        />
      </LinkBox>

      <CardLabel
        href={href}
        onPlay={onPlay}
        imageUrl={imageUrl}
        snippet={snippet}
        m='40px 0 0'
      />

      <CardButtons
        canBuy={canBuy}
        onToggleFavoriteFeature={onToggleFavoriteFeature}
        onBuy={onBuy}
        isFavorite={isFavorite}
        sharePrice={sharePrice}
      />
    </GradientBox>
  );
};
