import { Snippet } from '@/features/audio-player/types';
import {
  CrowdFundingCampaignStatusEnum,
  Product,
} from '@/shared/api/codegen/demuzo';
import { Card } from '@/shared/ui-kit/organisms/card';
import {
  calculateDaysLeft,
  calculateProgressStartDay,
} from '@/shared/utils/calculate-days-left';
import hardPush from '@/shared/utils/hard-push';
import { Flex } from '@chakra-ui/react';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { usePlayerControls, usePlayList } from '@/features/audio-player';
import { signIn, useSession } from 'next-auth/react';

type Props = {
  products: (Omit<Product, 'snippets'> & { snippets: Snippet[] })[];
  onToggleFavoriteFeature: ({
    evt,
    productId,
    isFavorite,
    isLogged,
  }: {
    evt: MouseEvent<HTMLButtonElement>;
    productId: string;
    isFavorite: boolean;
    isLogged: boolean;
  }) => void;
};

export const MobileHomeFeaturedList: FC<Props> = ({
  products,
  onToggleFavoriteFeature,
}) => {
  const session = useSession();
  const { updateData } = usePlayerData();
  const { setPlayList } = usePlayList();
  const { play, pause, checkIsActiveTrack } = usePlayerControls();

  const handlePlay = (
    snippet: Snippet,
    campaignId: string,
    isFavorite: boolean,
    canBuy: boolean,
  ) => {
    setPlayList([snippet]);
    updateData({
      currentCampaignId: campaignId,
      isFavoriteCampaign: isFavorite,
      canBuy,
    });
    checkIsActiveTrack(snippet) ? pause() : play();
  };
  const onBuyClick = useCallback(
    async (id: string) => {
      if (session.status === 'unauthenticated') {
        await signIn('keycloak');
        return;
      }

      hardPush(`/project/purchase/${id}`);
    },
    [session],
  );
  return (
    <Flex
      direction='column'
      gap='20px'
    >
      {products.map((product) => {
        const snippet = product.snippets.at(0);

        const handleCurrentPlay = () => {
          updateData({
            currentCampaignId: product.id,
            isFavoriteCampaign: product.isFavorite,
            canBuy: product.crowdFundingCampaign.status === 'in_progress',
          });
        };

        return (
          <Card
            href={`/project/${product.id}`}
            key={product.id}
            snippet={snippet}
            imageUrl={product.coverUrls.at(0) ?? ''}
            onPlay={handleCurrentPlay}
            onToggleFavoriteFeature={(evt) => {
              onToggleFavoriteFeature({
                evt,
                productId: product.id,
                isFavorite: product.isFavorite,
                isLogged: true,
              });
            }}
            canBuy={
              product.crowdFundingCampaign.status ===
              CrowdFundingCampaignStatusEnum.in_progress
            }
            onBuy={() => {
              onBuyClick(product.id);
            }}
            isFavorite={product.isFavorite}
            totalInvested={product.crowdFundingCampaign.totalInvested}
            daysLeft={calculateDaysLeft(
              product.crowdFundingCampaign.startDateTime,
              product.crowdFundingCampaign.endDateTime,
            )}
            limit={product.crowdFundingCampaign.successThreshold}
            progress={Number(product.crowdFundingCampaign.progress)}
            crowdFundingCampaignStatus={product.crowdFundingCampaign.status}
            progressToStart={
              product.crowdFundingCampaign.createdAt
                ? calculateProgressStartDay(
                    product.crowdFundingCampaign.createdAt,
                    product.crowdFundingCampaign.startDateTime,
                  )
                : 1
            }
            artistName={product.artist.name}
            productName={product.name}
            genres={`${product.primaryGenre} · ${product.secondaryGenre}`}
            avatarUrl={product.artist.avatarUrl}
            sharePrice={product.crowdFundingCampaign.sharePrice}
          />
        );
      })}
    </Flex>
  );
};
