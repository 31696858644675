import { REVALIDATE } from '@/entities/constants';
import { demuzoApi } from '@/shared/api';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';

type Props = {
  limit: number;
  offset: number;
};

export const getProductsAllPrefetchConfig = () =>
  ({
    queryKey: [...productsKeys.getProductsAll({ limit: 10, offset: 0 })],
    queryFn: async () => {
      const response = await demuzoApi.getProductsV1All(
        { limit: 10, offset: 0 },
        { next: { revalidate: REVALIDATE } },
      );

      return response.items.map(mapApiProductToProduct);
    },
  }) satisfies FetchQueryOptions;

export const useGetProductsAll = ({ limit, offset }: Props) => {
  return useQuery({
    queryKey: [...productsKeys.getProductsAll({ limit, offset })],
    queryFn: async () => {
      const response = await demuzoApi.getProductsV1All({ offset, limit });
      return response.items.map(mapApiProductToProduct);
    },
  });
};
