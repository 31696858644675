import { demuzoApi } from '@/shared/api';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { mapApiProductToProduct } from '../lib';
import { productsKeys } from '../query-keys';

export const getProductV1FeaturedConfig = queryOptions({
  queryKey: [...productsKeys.getFeatured()],
  queryFn: async () => {
    const response = await demuzoApi.getProductV1Featured({
      next: { revalidate: 3600 },
    });

    const mappedProducts = response.items.map(mapApiProductToProduct);

    return {
      ...response,
      items: mappedProducts,
    };
  },
});

export const useGetFeatured = () => {
  return useQuery(getProductV1FeaturedConfig);
};
