import { productsKeys } from '@/entities/product/model/query-keys';
import { demuzoApi } from '@/shared/api';
import {
  PostProductsV1Error,
  PostPutProductsRequest,
  Product,
} from '@/shared/api/codegen/demuzo';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const usePostProduct = () => {
  const queryClient = useQueryClient();

  return useMutation<Product, PostProductsV1Error, PostPutProductsRequest>({
    mutationFn: (productData) =>
      demuzoApi.postProductsV1({ postPutProductsRequest: productData }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productsKeys.all,
      });
    },
  });
};
